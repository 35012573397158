import { ideaStepFluxConstants } from '../constants';
import { ideaStepFluxService } from '../services';
import { alertActions } from './alert.actions';
import { domainsActions } from './domains.actions'; 
import { history } from '../helpers';

export const ideaStepFluxActions = {
  createIdeaStepFlux,
  deleteStepFlux,
  editIdeaStepFlux,
  setIdeaStepFluxData,
  clearIdeaStepFluxData,
  setNewContent,
  setNewContentSwitch,
  setDecisionName,
  setEvaluationLevel,
  setNotificationControls,
  setIdeaContributorsCanApproveStep,
  setVisualizationConfigStep,
  loadStepDetails,
  pushNewDecision,
  setDecisionNotification,
  updateGenericDecision,
  updateGenericTitle,
}

function createIdeaStepFlux(ideaStepFlux) {
  return async dispatch => {
    dispatch({ type: ideaStepFluxConstants.CREATE_IDEA_STEP_FLUX_REQUEST });

    try {
      await ideaStepFluxService.createIdeaStepFlux(ideaStepFlux);
      dispatch({ type: ideaStepFluxConstants.CREATE_IDEA_STEP_FLUX_SUCCESS });
      dispatch(alertActions.success('Etapa criada com sucesso!'));
      clearIdeaStepFluxData()
      history.push('/preferences/flux-of-ideas');
    } catch(error) {
      const err = error.response?.data?.errors;
      dispatch({
        type: ideaStepFluxConstants.CREATE_IDEA_STEP_FLUX_FAILURE,
        error: error.toString()
      });
      dispatch(alertActions.error(err));
    }
  }
}

function editIdeaStepFlux(ideaStepFlux) {
  return async dispatch => {
    dispatch({ type: ideaStepFluxConstants.EDIT_IDEA_STEP_FLUX_REQUEST });

    try {
      await ideaStepFluxService.editIdeaStepFlux(ideaStepFlux);
      dispatch({ type: ideaStepFluxConstants.EDIT_IDEA_STEP_FLUX_SUCCESS });
      dispatch(alertActions.success('Etapa alterada com sucesso!'));
      clearIdeaStepFluxData();
      history.push('/preferences/flux-of-ideas');
    } catch(error) {
      const err = error.response?.data?.errors;
      dispatch({
        type: ideaStepFluxConstants.EDIT_IDEA_STEP_FLUX_FAILURE,
        error: error.toString()
      });
      dispatch(alertActions.error(err));
    }
  }
}

function deleteStepFlux(_id) {
  return async dispatch => {
    dispatch({ type: ideaStepFluxConstants.DELETE_IDEA_STEP_FLUX_REQUEST });

    try {
      await ideaStepFluxService.removeIdeaStepFlux(_id);
      dispatch({ type: ideaStepFluxConstants.DELETE_IDEA_STEP_FLUX_SUCCESS });
      dispatch(alertActions.success('Etapa excluída com sucesso!'));
      clearIdeaStepFluxData();

      dispatch(domainsActions.getDomains());
    } catch(error) {
      const err = error.response?.data?.errors;
      dispatch({
        type: ideaStepFluxConstants.DELETE_IDEA_STEP_FLUX_FAILURE,
        error: error.toString()
      });
      dispatch(alertActions.error(err));
    }
  }
}

function setIdeaStepFluxData(ideaStepFlux) {
  return async dispatch => {
    dispatch({ type: ideaStepFluxConstants.SET_IDEA_STEP_FLUX_DATA, payload: ideaStepFlux })
  }
}

function setNewContent({ name, value }) {
  return (dispatch) => {
    dispatch({ type: ideaStepFluxConstants.SET_NEW_CONTENT, payload: { name, value } });
  }
}

function setDecisionName({ typeDecision, value }) {
  return (dispatch) => {
    dispatch({ type: ideaStepFluxConstants.SET_DECISION_NAME, payload: { typeDecision, value } });
  }
}

function setDecisionNotification({ value }) {
  return (dispatch) => {
    dispatch({ type: ideaStepFluxConstants.SET_DECISION_NOTIFICATION, payload: { value } });
  }
}

function setEvaluationLevel({ name, checked }) {
  return (dispatch) => {
    dispatch({ type: ideaStepFluxConstants.SET_EVALUATION_LEVEL, payload: { name, checked } });
  }
}

function setNotificationControls({ name, checked }) {
  return (dispatch) => {
    dispatch({ type: ideaStepFluxConstants.SET_NOTIFICAITON_LEVEL, payload: { name, checked } });
  }
}

function setIdeaContributorsCanApproveStep({ name, checked }) {
  return (dispatch) => {
    dispatch({ type: ideaStepFluxConstants.SET_IDEA_CONTRIBUTORS_CAN_APPROVE_STEP, payload: { name, checked } });
  }
}

function setVisualizationConfigStep({ name }) {
  return (dispatch) => {
    dispatch({ type: ideaStepFluxConstants.SET_VISUALIZATION_CONFIG, payload: { name } });
  }
}

function setNewContentSwitch({ name, checked }) {
  return (dispatch) => {
    dispatch({ type: ideaStepFluxConstants.SET_NEW_CONTENT_SWITCH, payload: { name, checked } });
  }
}

function clearIdeaStepFluxData() {
  return async dispatch => {
    dispatch({ type: ideaStepFluxConstants.CLEAR_IDEA_STEP_FLUX_DATA })
  }
}

function loadStepDetails(data) {
  return dispatch => {
    dispatch({
      type: ideaStepFluxConstants.LOAD_STEP_DETAILS,
      payload: data
    });
  }
}

function pushNewDecision({ checked, newDecision }) {
  return dispatch => {
    dispatch({
      type: ideaStepFluxConstants.PUSH_NEW_DECISION,
      payload: { checked, newDecision }
    })
  };
}

function updateGenericDecision(value) {
  return dispatch => {
    dispatch({
      type: ideaStepFluxConstants.UPDATE_GENERIC_DECISION,
      payload: { value }
    });
  }
}

function updateGenericTitle(name) {
  return dispatch => {
    dispatch({
      type: ideaStepFluxConstants.UPDATE_GENERIC_TITLE,
      payload: { name }
    });
  }
}