import React from 'react';
import moment from 'moment';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Grid, IconButton, TextField, Typography, useTheme } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import DeleteIcon from '@material-ui/icons/Delete';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DescriptionIcon from '@material-ui/icons/Description';
import { collapseCss } from './collapseCss';
import { AvatarIconWithText, DatePicker, LoadingButton } from '../Shared';
import { executionActions, fluxOfIdeasActions, ideaConclusionActions } from '../../actions';
import { selectedLanguageDateInputFormat } from '../../helpers/input-date-format';
import { TextAreaCustom } from './TextAreaCustom';
import { formatReal } from '../../helpers';

export function ValidationControl({ hasIdeaFlux }) {
  const { id } = useParams();
  const history = useHistory();
  const classes = collapseCss();
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    loading,
    estimatedStartDate,
    estimatedConclusionDate,
    estimatedFinancialReturn,
    costsFields
  } = useSelector((state) => state.execution);
  const {
    startDate,
    conclusionDate,
    financialReturn,
    conclusionDetails,
  } = useSelector(state => state.ideaConclusion);
  const language = useSelector((state) => state.language);
  const { loadingUpdate } = useSelector((state) => state.fluxIdeas);
  const regexPattern = new RegExp(',', 'g');
  const regexPointPattern = new RegExp('[.]', 'g');
  const ideaStepFlux = hasIdeaFlux?.currentStepFlux?.ideaStepFlux;

  function handleChangeConclusion(date, name) {
    if (date instanceof Date && isFinite(date)) {
      dispatch(ideaConclusionActions.setConclusionInput(date, name));
    }
  }

  function handleChangeCost(value, index, name) {
    if (name === 'estimatedCost' || name === 'cost') {
      const formatedValue = value.replace(regexPointPattern, '').replace(regexPattern, '')
      if (!isNaN(formatedValue) && value.toString().length <= 14) {
        dispatch(executionActions.changeCost(formatReal(formatedValue), index, name))
      }
    } else {
      dispatch(executionActions.changeCost(value, index, name))
    }
  }

  function handleConclusionFinancialReturn(e) {
    const { name, value } = e.target;
    const formatedValue = value.replaceAll(",", "").replaceAll(".", "");

    if (!isNaN(formatedValue) && value.toString().length <= 14) {
      dispatch(ideaConclusionActions.setConclusionInput(formatReal(formatedValue), name));
    }
  }

  function handleChangeDetails(e) {
    const { name, value } = e.target;
    dispatch(ideaConclusionActions.setConclusionInput(value, name));
  }

  function handleDeleteField(index) {
    dispatch(executionActions.deleteCostField(index))
  }

  const getIcon = (icon, typeDecision) => (
    <span
      className="material-icons"
      style={{
        color: typeDecision === 'NextStepApproved' ? palette.neutrals.high.main : palette.primary.main,
        width: 30,
        textAlign: 'center',
      }}
    >
      {icon}
    </span>
  )

  async function handleUpdateExecution(typeDecision) {
    let payload = {
      startDate,
      conclusionDate,
      conclusionDetails,
      financialReturn: Number(financialReturn.replace(regexPointPattern, '').replace(regexPattern, '.')),
      costs: costsFields.map(item => {
        let formattedEstimatedCost = item.estimatedCost.replace(regexPointPattern, '').replace(regexPattern, '.');
        let formattedCost = item.cost.replace(regexPointPattern, '').replace(regexPattern, '.');

        return {
          ...item,
          estimatedCost: Number(formattedEstimatedCost),
          cost: Number(formattedCost)
        }
      }),
    }

    let evaluationData = {
      decision: ideaStepFlux?.decisions.filter(item => item.typeDecision === typeDecision)[0],
      managerObservation: conclusionDetails,
      personalizedEvaluationsRates: ideaStepFlux?.usePersonalizedEvaluation
    }

    await dispatch(executionActions.updateExecution(payload, id, t('ideia_em_execucao')));

    await dispatch(fluxOfIdeasActions.updateIdeaStageInFlux(
      id,
      evaluationData,
      t('sua_avaliacao_foi_feita_com_sucesso'),
      ideaStepFlux?.attachmentsRequired
    ));

    history.push(`/idea/${id}/general`);
  }

  return (
    <Grid container spacing={3}>
      {/* PRAZO DE EXECUÇÃO */}
      <Grid item xs={12}>
        <Box className={classes.cardControl}>
          <AvatarIconWithText
            text={t('prazo_execucao')}
            icon={<EventIcon color="primary" />}
          />
        
          <Grid container spacing={2} style={{ marginTop: '2rem' }}>
            <Grid item xs={2} className={classes.rowTitle}>
              <Typography variant="subtitle2">
                {t('estimado')}
              </Typography>
            </Grid>

            <Grid item xs={5}>
              <Typography variant="subtitle2">início</Typography>
              <Typography variant="body2">{moment(estimatedStartDate).format('DD/MM/YYYY')}</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography variant="subtitle2">termino</Typography>
              <Typography variant="body2">{moment(estimatedConclusionDate).format('DD/MM/YYYY')}</Typography>
            </Grid>

            <Grid item xs={2} className={classes.rowTitle}>
              <Typography variant="subtitle2">
                {t('real')}
              </Typography>
            </Grid>

            <Grid item xs={5}>
              <DatePicker
                required={true}
                format={selectedLanguageDateInputFormat(language)}
                name="startDate"
                label={t('inicio')}
                invalidDateMessage={t('data_de_inicio_invalida')}
                value={startDate}
                onChange={(data) => handleChangeConclusion(data, 'startDate')}
              />
            </Grid>
            <Grid item xs={5}>
              <DatePicker
                required={true}
                format={selectedLanguageDateInputFormat(language)}
                name="conclusionDate"
                label={t('termino')}
                invalidDateMessage={t('data_de_termino_invalida')}
                value={conclusionDate}
                minDate={startDate}
                onChange={(data) => handleChangeConclusion(data, 'conclusionDate')}
                minDateMessage={
                  <>
                    {t('atencao')}!{' '}
                    {t('a_data_de_termino_das_inscricoes_nao_pode_ser_inferior_a_data_de_inicio_e_de_cadastro_de_desafio')}
                  </>
                }
              />
            </Grid>
          </Grid>

        </Box>
      </Grid>

      {/* ESTRUTURA DE CUSTOS */}
      <Grid item xs={12}>
        <Box className={classes.cardControl}>
          <AvatarIconWithText
            text={t('estrutura_de_custos')}
            icon={<MoneyOffIcon color="primary" />}
          />
        
          <Box marginTop="2rem" style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
            {costsFields && costsFields.map((cost, index) => (
              <Box className={classes.rowContainer} key={index}>
                <Box display="flex" flexDirection="column" gap="10px" minWidth={300}>
                  <Typography variant="subtitle2">despesa</Typography>
                  <Typography variant="body2">{cost?.costDescription}</Typography>
                </Box>

                <Box display="flex" flexDirection="column" gap="10px" minWidth={200}>
                  <Typography variant="subtitle2">R$ estimado</Typography>
                  <Typography variant="body2">{cost?.estimatedCost}</Typography>
                </Box>

                <TextField
                  name="cost"
                  label={t('real')}
                  variant="filled"
                  fullWidth
                  type="text"
                  onChange={(e) => handleChangeCost(e.target.value, index, 'cost')}
                  value={cost?.cost}
                />
                <IconButton onClick={() => handleDeleteField(index)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
          </Box>
        </Box>
      </Grid>

      {/* RETORNO FINANCEIRO */}
      <Grid item xs={12}>
        <Box className={classes.cardControl}>
          <AvatarIconWithText
            text={t('retorno_financeiro')}
            icon={<AttachMoneyIcon color="primary" />}
          />
        
          <Box className={classes.rowContainer} marginTop="2rem">
            <Box display="flex" flexDirection="column" gap="10px" minWidth="50%">
              <Typography variant="subtitle2">valor estimado (R$)</Typography>
              <Typography variant="body2">{estimatedFinancialReturn}</Typography>
            </Box>

            <TextField
              name="financialReturn"
              label={`${t('valor_real')} (${t('dinheiro')})`}
              variant="filled"
              placeholder="0"
              fullWidth
              type="text"
              onChange={handleConclusionFinancialReturn}
              inputRef={{ required: t('o_retorno_financeiro_e_obrigatorio') }}
              value={financialReturn}
            />
          </Box>
        </Box>
      </Grid>

      {/* DETALHES */}
      <Grid item xs={12}>
        <Box className={classes.cardControl}>
          <AvatarIconWithText
            text={t('detalhes')}
            icon={<DescriptionIcon color="primary" />}
          />
        
          <Box marginTop="2rem">
            <TextAreaCustom
              name="conclusionDetails"
              label={t('detalhes_de_execucao')}
              defaultValue={conclusionDetails}
              onChange={handleChangeDetails}
              inputRef={{
                maxLength: 2000,
                required: t('os_detalhes_do_desafio_e_obrigatorio'),
              }}
            />
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-end" style={{ gap: 20 }}>
          {(loading || loadingUpdate) ? <LoadingButton /> : (
          ideaStepFlux?.decisions
            .map(({ title, typeDecision, icon }, index) => (
              <Button
                key={index}
                text={title}
                color="primary"
                variant={typeDecision === 'NextStepApproved' ? 'contained' : 'outlined'}
                startIcon={getIcon(icon, typeDecision)}
                onClick={() => handleUpdateExecution(typeDecision)}
              >
                {title}
              </Button>
            )).reverse()
          )}
        </Box>
      </Grid>
    </Grid>
  );
}