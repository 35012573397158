import { ideaStepFluxConstants } from '../constants';
import update from 'react-addons-update';

const initialState = {
  attachmentsRequired: false,
  approveInStepConcludeIdea: false,
  rejectInStepConcludeIdea: false,
  canApproverRequestReview: false,
  decisions: [
    {
      title: "",
      typeDecision: "NextStepRejected",
      icon: "close"
    },
    {
      title: "",
      typeDecision: "NextStepApproved",
      icon: "arrow_forward"
    },
  ],
  evaluationLevel: {
    canDirectManagerEvaluateStep: false,
    canAreaManagerEvaluateStep: false,
    canOriginAreaManagerEvaluateStep: false,
    teamAndAreaManagerEvaluateStep: false
  },
  evaluationNeedObservation: true,
  evaluationTeams: [],
  ideaContributorsCanApproveStep: false,
  ideaFlux: "", // pegar do domains e colocar aqui o default
  ideaExecutionIsRequired: false,
  ideaFinishExecutionIsRequired: false,
  name: "",
  notificationsControl: {
    notifyDirectManagerWhenCreated: false,
    notifyAreaManagersWhenCreated: false,
    notifyOriginAreaManagersWhenCreated: false,
    notifyEvaluationTeamsWhenCreated: false,
    notifyDirectManagerWhenDecision: false,
    notifyAreaManagersWhenDecision: false,
    notifyOriginAreaManagersWhenDecision: false,
    notifyEvaluationTeamsWhenDecision: false,
    notifyIdeaContributorsWhenDecision: true,
    notifyEvaluatorsWhenNewPending: true
  },
  optionIdeiaInTimeline: false,
  personalizedEvaluationsGroups: [],
  scoresWhenApprovedInStep: [],
  status: true,
  showStepInCuration: true,
  showResultsInStep: false,
  visualizationConfigs: {
    showInEvaluationTab: true,
    showInExecutionTab: false,
    showInValidationTab: false,
    showInCurationEvaluation: false
  },
  usePersonalizedEvaluation: false,
  labelColor: "",
  canEditIdea: true,
  loadingStepDetails: true,
  returnTheIdeaToAnotherStage: false,
  decisionTitle: '',
  notificationMessagePendingReview: '',
  stepDescription: ''
};

export function ideaStepFlux(state = initialState, action) {
  switch (action.type) {
    case ideaStepFluxConstants.CREATE_IDEA_STEP_FLUX_REQUEST:
    case ideaStepFluxConstants.EDIT_IDEA_STEP_FLUX_REQUEST:
    case ideaStepFluxConstants.DELETE_IDEA_STEP_FLUX_REQUEST:
      return {
        ...state,
        loading: true
      };

    case ideaStepFluxConstants.CREATE_IDEA_STEP_FLUX_SUCCESS:
    case ideaStepFluxConstants.EDIT_IDEA_STEP_FLUX_SUCCESS:
    case ideaStepFluxConstants.DELETE_IDEA_STEP_FLUX_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case ideaStepFluxConstants.CREATE_IDEA_STEP_FLUX_FAILURE:
    case ideaStepFluxConstants.EDIT_IDEA_STEP_FLUX_FAILURE:
    case ideaStepFluxConstants.DELETE_IDEA_STEP_FLUX_FAILURE:
      return {
        ...state,
        loading: false
      };

    case ideaStepFluxConstants.SET_IDEA_STEP_FLUX_DATA:
      return {
        ...action.payload,
        loading: false
      };

    case ideaStepFluxConstants.CLEAR_IDEA_STEP_FLUX_DATA:
      return {
        ...initialState
      };

    case ideaStepFluxConstants.SET_NEW_CONTENT:
      return update(state, {
        [action.payload.name]: { $set: action.payload.value }
      });

    case ideaStepFluxConstants.SET_NEW_CONTENT_SWITCH:
      return update(state, {
        [action.payload.name]: { $set: action.payload.checked }
      });
      
    case ideaStepFluxConstants.SET_EVALUATION_LEVEL:
      return update(state, {
        evaluationLevel: {
          [action.payload.name]: { $set: action.payload.checked }
        }
      });

    case ideaStepFluxConstants.SET_NOTIFICAITON_LEVEL:
      return update(state, {
        notificationsControl: {
          [action.payload.name]: { $set: action.payload.checked }
        }
      });

    case ideaStepFluxConstants.SET_VISUALIZATION_CONFIG:
      return {
        ...state,
        visualizationConfigs: {
          ...state.visualizationConfigs,
          showInEvaluationTab: action.payload.name === "showInEvaluationTab",
          showInExecutionTab: action.payload.name === "showInExecutionTab",
          showInValidationTab: action.payload.name === "showInValidationTab",
          showInCurationEvaluation: action.payload.name === "showInCurationEvaluation",
        }
      };

    case ideaStepFluxConstants.SET_IDEA_CONTRIBUTORS_CAN_APPROVE_STEP:
      return update(state, {
        [action.payload.name]: { $set: action.payload.checked }
      });

    case ideaStepFluxConstants.SET_DECISION_NAME:
      return {
        ...state,
        decisions: state.decisions.map(decision =>
            decision.typeDecision === action.payload.typeDecision
              ? { ...decision, title: action.payload.value }
              : decision
          )
      };

    case ideaStepFluxConstants.SET_DECISION_NOTIFICATION:
      const decisionI = state.decisions.findIndex(item => item.typeDecision === 'NextStepPendingReview');
      const allDecisions = state.decisions;
      allDecisions[decisionI].notificationMessagePendingReview = action.payload.value
      
      return update(state, {
        decisions: { $set: allDecisions }
      });
  
    case ideaStepFluxConstants.PUSH_NEW_DECISION:
      if (action.payload.checked) {
        return update(state, {
          returnTheIdeaToAnotherStage: { $set: action.payload.checked },
          decisions: { $push: [action.payload.newDecision] },
          canApproverRequestReview: { $set: action.payload.checked }
        });
      } else {
        const removeDecision = state.decisions.filter(
          (decision) => decision.typeDecision !== action.payload.newDecision.typeDecision
        );

        return update(state, {
          returnTheIdeaToAnotherStage: { $set: action.payload.checked },
          decisions: { $set: removeDecision },
          canApproverRequestReview: { $set: action.payload.checked }
        });
      }
  
    case ideaStepFluxConstants.UPDATE_GENERIC_DECISION:
      return {
        ...state,
        decisions: state.decisions
          .map(decision => decision.typeDecision === 'NextStepGeneric'
            ? { ...decision, nextStepWhenGeneric: action.payload.value }
            : decision
          )
      }

    case ideaStepFluxConstants.UPDATE_GENERIC_TITLE:
      return {
        ...state,
        decisions: state.decisions
          .map(decision => decision.typeDecision === 'NextStepGeneric'
            ? { ...decision, title: action.payload.name }
            : decision
          )
      }

    case ideaStepFluxConstants.LOAD_STEP_DETAILS:
      let updatedDecisions = [...action.payload.decisions];
      const decisionTypes = new Set(updatedDecisions.map(item => item.typeDecision));

      if (updatedDecisions.length === 0) {
        updatedDecisions = [...initialState.decisions];
      } else {
        if (decisionTypes.has("NextStepPendingReview") && !decisionTypes.has("NextStepRejected") && !decisionTypes.has("NextStepApproved")) {
          updatedDecisions.push(initialState.decisions);
        } else if (decisionTypes.has("NextStepPendingReview") && decisionTypes.has("NextStepRejected") && !decisionTypes.has("NextStepApproved")) {
          updatedDecisions.push({
            title: "",
            typeDecision: "NextStepApproved",
            icon: "arrow_forward",
          });
        } else if (decisionTypes.has("NextStepPendingReview") && !decisionTypes.has("NextStepRejected") && decisionTypes.has("NextStepApproved")) {
          updatedDecisions.push({
            title: "",
            typeDecision: "NextStepRejected",
            icon: "close",
          });
        } else if (!decisionTypes.has("NextStepPendingReview") && !decisionTypes.has("NextStepRejected") && decisionTypes.has("NextStepApproved")) {
          updatedDecisions.push({
            title: "",
            typeDecision: "NextStepRejected",
            icon: "close",
          });
        } else if (!decisionTypes.has("NextStepPendingReview") && decisionTypes.has("NextStepRejected") && !decisionTypes.has("NextStepApproved")) {
          updatedDecisions.push({
            title: "",
            typeDecision: "NextStepApproved",
            icon: "arrow_forward",
          });
        } else if (!decisionTypes.has("NextStepPendingReview") && !decisionTypes.has("NextStepRejected") && !decisionTypes.has("NextStepApproved")) {
          updatedDecisions.push(initialState.decisions);
        }
      }

      return {
        ...state,
        _id: action.payload._id,
        attachmentsRequired: action.payload.attachmentsRequired,
        approveInStepConcludeIdea: action.payload.approveInStepConcludeIdea,
        rejectInStepConcludeIdea: action.payload.rejectInStepConcludeIdea,
        canApproverRequestReview: action.payload.canApproverRequestReview,
        decisions: updatedDecisions,
        evaluationLevel: {
          ...state.evaluationLevel,
          canDirectManagerEvaluateStep: action.payload.evaluationLevel.canDirectManagerEvaluateStep,
          canAreaManagerEvaluateStep: action.payload.evaluationLevel.canAreaManagerEvaluateStep,
          canOriginAreaManagerEvaluateStep: action.payload.evaluationLevel.canOriginAreaManagerEvaluateStep,
          teamAndAreaManagerEvaluateStep: action.payload.evaluationLevel.teamAndAreaManagerEvaluateStep
        },
        evaluationNeedObservation: action.payload.evaluationNeedObservation,
        evaluationTeams: action.payload.evaluationTeams,
        ideaContributorsCanApproveStep: action.payload.ideaContributorsCanApproveStep,
        ideaFlux: action.payload.ideaFlux,
        ideaExecutionIsRequired: action.payload.ideaExecutionIsRequired,
        ideaFinishExecutionIsRequired: action.payload.ideaFinishExecutionIsRequired,
        name: action.payload.name,
        notificationsControl: {
          ...state.notificationsControl,
          notifyDirectManagerWhenCreated: action.payload.notificationsControl.notifyDirectManagerWhenCreated,
          notifyAreaManagersWhenCreated: action.payload.notificationsControl.notifyAreaManagersWhenCreated,
          notifyOriginAreaManagersWhenCreated: action.payload.notificationsControl.notifyOriginAreaManagersWhenCreated,
          notifyEvaluationTeamsWhenCreated: action.payload.notificationsControl.notifyEvaluationTeamsWhenCreated,
          notifyDirectManagerWhenDecision: action.payload.notificationsControl.notifyDirectManagerWhenDecision,
          notifyAreaManagersWhenDecision: action.payload.notificationsControl.notifyAreaManagersWhenDecision,
          notifyOriginAreaManagersWhenDecision: action.payload.notificationsControl.notifyOriginAreaManagersWhenDecision,
          notifyEvaluationTeamsWhenDecision: action.payload.notificationsControl.notifyEvaluationTeamsWhenDecision,
          notifyIdeaContributorsWhenDecision: action.payload.notificationsControl.notifyIdeaContributorsWhenDecision,
          notifyEvaluatorsWhenNewPending: action.payload.notificationsControl.notifyEvaluatorsWhenNewPending
        },
        optionIdeiaInTimeline: action.payload.optionIdeiaInTimeline,
        personalizedEvaluationsGroups: action.payload.personalizedEvaluationsGroups,
        scoresWhenApprovedInStep: action.payload.scoresWhenApprovedInStep,
        status: action.payload.status,
        showStepInCuration: action.payload.showStepInCuration,
        showResultsInStep: action.payload.showResultsInStep,
        visualizationConfigs: {
          ...state.visualizationConfigs,
          showInEvaluationTab: action.payload.visualizationConfigs.showInEvaluationTab,
          showInExecutionTab: action.payload.visualizationConfigs.showInExecutionTab,
          showInValidationTab: action.payload.visualizationConfigs.showInValidationTab,
          showInCurationEvaluation: action.payload.visualizationConfigs.showInCurationEvaluation
        },
        usePersonalizedEvaluation: action.payload.usePersonalizedEvaluation,
        labelColor: action.payload.labelColor,
        canEditIdea: action.payload.canEditIdea,
        loadingStepDetails: false,
        returnTheIdeaToAnotherStage: action.payload.decisions.some(item => item.typeDecision === 'NextStepPendingReview'),
        stepDescription: action.payload.stepDescription
      }

    default: {
      return state;
    }
  }
}