import React from 'react';
import { Grid, Button, Box, CircularProgress, Typography, useTheme } from '@material-ui/core';
import {
  DeadlineCollapse,
  DetailsCollapse,
  FinancialFeedbackCollapse,
  CostStructureCollapse,
  DialogJustification,
} from '../../components';
import { useTranslation } from 'react-i18next';
import { LoadingButton, DialogExecutionFlow } from '../Shared';
import { useIdeaDetailsController } from '../../containers/IdeaDetailsPage';
import { useIdeaConclusionController } from './hooks';
import { IdeaCss } from './IdeaCss';

export function IdeaConclusion() {
  const classes = IdeaCss();
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { hasIdeaFlux } = useIdeaDetailsController();
  const {
    id, 
    loading,
    loadingUpdate,
    openDialog,
    dialogIsOpen,
    loadingConclusion,
    sendConclusion,
    sendOthersDecisions,
    openDialogConclusion,
    closeDialogConclusion,
    openModelJustification,
    onCloseDialogJustification
  } = useIdeaConclusionController();
  const decisions = hasIdeaFlux?.currentStepFlux?.ideaStepFlux?.decisions;

  const getIcon = (icon, typeDecision) => (
    <span
      className="material-icons"
      style={{
        color: typeDecision === 'NextStepApproved' ? palette.neutrals.high.main : palette.primary.main,
        width: 30,
        textAlign: 'center',
      }}
    >
      {icon}
    </span>
  );

  return (
    <>
      <DialogExecutionFlow
        open={dialogIsOpen}
        onClose={closeDialogConclusion}
        title={t('concluir_execucao')}
        subtitle={t('deseja_concluir_a_execucao_dessa_ideia')}
        description={t('as_informacoes_dos_resultados_da_execucao_nao_poderao_ser_editadas_posteriormente')}
      >
        {(loadingConclusion || loadingUpdate) ? <LoadingButton /> : (
          <Box>
            <Button variant="outlined" color="primary" onClick={closeDialogConclusion}>
              {t('voltar')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: 23 }}
                onClick={sendConclusion}
            >
              {decisions.filter(item => item.typeDecision === 'NextStepApproved').map(item => item.title)[0]}
            </Button>
          </Box>
        )}
      </DialogExecutionFlow>

      <DialogJustification
        open={openDialog}
        onClose={onCloseDialogJustification}
        id={id}
      />

      {loading ? (
        <Box display='flex' justifyContent='center' alignItems='center' height='80vh' width='100%'>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" className={classes.titleUpperCase}>
              {t('execucao')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <DeadlineCollapse isConclusion />
          </Grid>
          <Grid item xs={12}> 
            <CostStructureCollapse isConclusion />
          </Grid>
          <Grid item xs={12}>
            <FinancialFeedbackCollapse isConclusion />
          </Grid>
          <Grid item xs={12}>
            <DetailsCollapse isConclusion />
          </Grid>
          <Grid item xs={12}>
            <Box display='flex' justifyContent='flex-end' width='100%' style={{ gap: 10 }}>
              {(loading || loadingUpdate) ? <LoadingButton /> : (
               decisions.map(({ title, typeDecision, icon }, index) => (
                  <Button
                    key={index}
                    text={title}
                    color="primary"
                    variant={typeDecision === 'NextStepApproved' ? 'contained' : 'outlined'}
                    startIcon={getIcon(icon, typeDecision)}
                    onClick={() => typeDecision === 'NextStepApproved'
                      ? openDialogConclusion()
                      : typeDecision === 'NextStepRejected'
                        ? openModelJustification()
                        : sendOthersDecisions(typeDecision)
                    }
                  >
                    {title}
                  </Button>
                )).reverse()
              )}
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
}