import { fluxOfIdeasConstants, ideaCreateConstans, ideaGeneralEvaluationConstants } from '../constants';
import { challengeActions, ideaActions, ideaCreateDialogsActions } from '../actions';
import { fluxOfIdeasService, ideaGeneralEvaluationService, ideaService } from '../services';
import { alertActions } from './alert.actions';
import { getFiles } from '../helpers';

export const fluxOfIdeasActions = {
  createIdeaInFlux,
  getIdeaInFlux,
  updateIdeaStageInFlux,
  getEvaluationsByGroup,
  getIdeasUserStepFlux,
  setManagerObservation,
  clearFluxOfIdeas,
  uploadFluxIdeasFiles,
  ideaVisibleInTimeline,
  setEvaluationRate,
  openAttachmentDialog,
  setImagesEvidence,
  setVideoEvidence,
  setFilesEvidence,
  removeImageEvidence,
  removeVideoEvidence,
  removeFileEvidence,
  uploadEvidences,
  loadEvidences,
  clearEvidences
}

function createIdeaInFlux(idea, successMessage, isDetails) {
  const contributors = idea.contributors.map(({ id }) => id);
  idea.contributors = contributors;

  return async dispatch => {
    dispatch({ type: fluxOfIdeasConstants.CREATE_IDEA_IN_FLUX_REQUEST });

    try {
      const ideaResponse = await fluxOfIdeasService.createIdeaInFlux(idea);
      dispatch({ type: fluxOfIdeasConstants.CREATE_IDEA_IN_FLUX_SUCCESS });

      const files = getFiles(idea);
      await ideaService.uploadFiles(files, ideaResponse.data._id);

      if (idea.ideaCreateChallenge) {
        dispatch(challengeActions.getChallengeById(idea.challenge._id));
      }

      if (isDetails) {
        dispatch(getIdeaInFlux(ideaResponse.data._id));
      } else {
        dispatch(ideaActions.getIdeaDetails(ideaResponse.data._id));
      }

      dispatch(alertActions.success(successMessage));
    } catch(error) {
      const err = error.response?.data?.errors;
      dispatch({
        type: fluxOfIdeasConstants.CREATE_IDEA_IN_FLUX_FAILURE,
        error: error.toString()
      });

      dispatch(alertActions.error(err));
    } finally {
      dispatch(ideaCreateDialogsActions.close());
      dispatch({ type: ideaCreateConstans.CLEAR });
    }
  }
}

function getIdeaInFlux(id) {
  return dispatch => {
    dispatch({ type: fluxOfIdeasConstants.GET_IDEA_DETAILS_IN_FLUX_REQUEST });
    fluxOfIdeasService.getIdeaInFlux(id)
      .then((idea) => {
        dispatch({
          type: fluxOfIdeasConstants.GET_IDEA_DETAILS_IN_FLUX_SUCCESS,
          payload: { idea }
        });
      })
      .catch((error) => {
        dispatch({
          type: fluxOfIdeasConstants.GET_IDEA_DETAILS_IN_FLUX_FAILURE,
          error: error.toString()
        });
      })
  }
}

function updateIdeaStageInFlux(ideaId, body, successMessage, attachmentRequired, files) {
  const filesFiltered = files && files.filter(item => item.id);
  return async dispatch => {
    dispatch({ type: fluxOfIdeasConstants.UPDATE_IDEA_STAGE_IN_FLUX_REQUEST });
    try {
    if (attachmentRequired) {
        await fluxOfIdeasService.uploadFiles(filesFiltered, ideaId);
      }
      const { data } = await fluxOfIdeasService.updateIdeaStageInFlux(ideaId, body);
              dispatch({
                type: fluxOfIdeasConstants.UPDATE_IDEA_STAGE_IN_FLUX_SUCCESS,
                payload: data.data
              });
              dispatch(alertActions.success(successMessage));
              dispatch(clearFluxOfIdeas());
              dispatch(getIdeaInFlux(ideaId));
    } catch (error) {
      if (error.response && error.response.data.errors) {
        dispatch(alertActions.error(error.response?.data?.errors.substring(7)));
      }

      dispatch({
        type: fluxOfIdeasConstants.UPDATE_IDEA_STAGE_IN_FLUX_FAILURE,
        error: error.toString()
      });
    }
  }
}

function getEvaluationsByGroup(groupId) {
  return dispatch => {
    dispatch({ type: ideaGeneralEvaluationConstants.GET_EVALUATIONS_BY_GROUP_REQUEST });
    ideaGeneralEvaluationService.getEvaluationsByGroup(groupId)
      .then(({ data }) => {
        dispatch({
          type: ideaGeneralEvaluationConstants.GET_EVALUATIONS_BY_GROUP_SUCCESS,
          payload: data.data
        });
      })
      .catch((error) => {
        dispatch({
          type: ideaGeneralEvaluationConstants.GET_EVALUATIONS_BY_GROUP_FAILURE,
          error: error
        });
      })
  }
}

function getIdeasUserStepFlux(page, sort, query, limit, stepsIdeas) {
  return dispatch => {
    dispatch({ type: fluxOfIdeasConstants.GET_USER_IDEAS_IN_FLUX_REQUEST });
    fluxOfIdeasService.getIdeasUserStepFlux(page, sort, query, limit, stepsIdeas)
      .then((ideasFlux) => {
        dispatch({
          type: fluxOfIdeasConstants.GET_USER_IDEAS_IN_FLUX_SUCCESS,
          payload: { ideasFlux, query }
        });
      })
      .catch((error) => {
        console.log(error, 'error')
        dispatch({ type: fluxOfIdeasConstants.GET_USER_IDEAS_IN_FLUX_FAILURE });
      })
  }
}

function setManagerObservation(value) {
  return dispatch => {
    dispatch({
      type: fluxOfIdeasConstants.SET_MANAGER_OBSERVATION,
      payload: { value }
    })
  }
}

function clearFluxOfIdeas() {
  return dispatch => {
    dispatch({ type: fluxOfIdeasConstants.CLEAR_FLUX_OF_IDEAS })
  }
}

function uploadFluxIdeasFiles(files, ideaId) {
  return dispatch => {
    fluxOfIdeasService.uploadFiles(files, ideaId).then(() => { }).catch(error => console.error(error))
  }
}

function ideaVisibleInTimeline(id, checked) {
  return dispatch => {
    dispatch({
      type: fluxOfIdeasConstants.TOGGLE_IDEA_FLUX_VISIBLE_IN_TIMELINE_REQUEST,
      payload: checked
    });
    fluxOfIdeasService.ideaVisibleInTimeline(id, checked).then(
      (response) => {
        dispatch({
          type: fluxOfIdeasConstants.TOGGLE_IDEA_FLUX_VISIBLE_IN_TIMELINE_SUCCESS,
        });
      },
      (error) => {
        dispatch({
          type: fluxOfIdeasConstants.TOGGLE_IDEA_FLUX_VISIBLE_IN_TIMELINE_FAILURE,
          error
        });
      }
    );
  }
}

function setEvaluationRate(index, rate) {
  return dispatch => {
    dispatch({
      type: fluxOfIdeasConstants.SET_EVALUATION_RATES_IN_FLUX_IDEA,
      payload: { index, rate } 
    });
  }
}

function openAttachmentDialog() {
  return dispatch => {
    dispatch({ type: fluxOfIdeasConstants.IDEA_FLUX_OPEN_ATTACHMENT_DIALOG });
  };
}

function setImagesEvidence(images, ideaId) {
  return dispatch => {
    const payload = { images };
    dispatch({ type: fluxOfIdeasConstants.IDEA_FLUX_SET_IMAGES_EVIDENCE, payload });

    if (images.length > 0) {
      dispatch(uploadEvidences(images, ideaId));
    }
  };
}

function setVideoEvidence(video, ideaId) {
  return dispatch => {
    const payload = { video };
    dispatch({ type: fluxOfIdeasConstants.IDEA_FLUX_SET_VIDEO_EVIDENCE, payload });
    
    if (video.length > 0) {
      dispatch(uploadEvidences(video, ideaId));
    }
  };
}

function setFilesEvidence(files, ideaId) {
  return dispatch => {
    const payload = { files };
    dispatch({ type: fluxOfIdeasConstants.IDEA_FLUX_SET_FILES_EVIDENCE, payload });

    if (files.length > 0) {
      dispatch(uploadEvidences(files, ideaId));
    }
  }
}

function removeImageEvidence(id) {
  return (dispatch) => {
    const payload = { id };

    dispatch({ type: fluxOfIdeasConstants.IDEA_FLUX_REMOVE_IMAGES_EVIDENCE, payload });
  };
}

function removeVideoEvidence(id) {
  return (dispatch) => {
    const payload = { id };

    dispatch({ type: fluxOfIdeasConstants.IDEA_FLUX_REMOVE_VIDEO_EVIDENCE, payload });
  };
}

function removeFileEvidence(id) {
  return (dispatch) => {
    const payload = { id };

    dispatch({ type: fluxOfIdeasConstants.IDEA_FLUX_REMOVE_FILES_EVIDENCE, payload });
  };
}

function uploadEvidences(files, ideaId) {
  return async (dispatch) => {
    dispatch({ type: fluxOfIdeasConstants.IDEA_FLUX_UPLOAD_EVIDENCES_REQUEST });

    try {
      const response = await fluxOfIdeasService.uploadImplementation(files, ideaId);
      dispatch({ type: fluxOfIdeasConstants.IDEA_FLUX_UPLOAD_EVIDENCES_SUCCESS });
  
      const allSuccessful = response.every(item => item.status === 200);
  
      if (allSuccessful) {
        dispatch(openAttachmentDialog());
        dispatch(clearEvidences());
        dispatch(getIdeaInFlux(ideaId));
      }
    } catch (err) {
      dispatch({
        type: fluxOfIdeasConstants.IDEA_FLUX_UPLOAD_EVIDENCES_FAILURE,
        error: err.toString()
      });
    }
  };
}

function loadEvidences(data) {
  return (dispatch) => {
    dispatch({
      type: fluxOfIdeasConstants.IDEA_FLUX_LOAD_EVIDENCES,
      payload: data
    });
  };
}

function clearEvidences() {
  return dispatch => {
    dispatch({ type: fluxOfIdeasConstants.IDEA_FLUX_CLEAR_EVIDENCES });
  }
}