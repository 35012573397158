import { ideaStepFluxUpdateConstants } from '../constants';
import { ideaStepFluxService } from '../services';
import { alertActions } from './alert.actions';
import { history } from '../helpers';

export const ideaStepFluxUpdateActions = {
  editIdeaStepFlux,
  setEditIdeaStepFluxAssociationsState,
  setNewContent,
  toggleGenericBtn,
  clearAllEditIdeaStepFlux
}

function editIdeaStepFlux(ideaStepFlux) {
  return async dispatch => {
    dispatch({ type: ideaStepFluxUpdateConstants.EDIT_IDEA_STEP_FLUX_UPDATE_ASSOCIATIONS_REQUEST });

    try {
      await ideaStepFluxService.editIdeaStepFlux(ideaStepFlux);
      dispatch({ type: ideaStepFluxUpdateConstants.EDIT_IDEA_STEP_FLUX_UPDATE_ASSOCIATIONS_SUCCESS });
      dispatch(alertActions.success('Etapa alterada com sucesso!'));
      history.push('/preferences/flux-of-ideas');
    } catch(error) {
      const err = error.response?.data.errors;
      dispatch({
        type: ideaStepFluxUpdateConstants.EDIT_IDEA_STEP_FLUX_UPDATE_ASSOCIATIONS_FAILURE,
        error: error.toString()
      });
      dispatch(alertActions.error(err));
    }
  }
}

function setNewContent({ name, value }) {
  return (dispatch) => {
    dispatch({ type: ideaStepFluxUpdateConstants.SET_NEW_CONTENT_FLUX, payload: { name, value } });
  }
}

function setEditIdeaStepFluxAssociationsState({
  _id,
  nextStepWhenApproved,
  nextStepWhenRejected,
  nextStepWhenPendingReview,
  nextStepGeneric
}) {
  return async dispatch => {
    dispatch({
      type: ideaStepFluxUpdateConstants.SET_EDIT_IDEA_STEP_FLUX_ASSOCIATIONS_STATE,
      payload: { _id, nextStepWhenApproved, nextStepWhenRejected, nextStepWhenPendingReview, nextStepGeneric }
    });
  }
}

function toggleGenericBtn({ name, checked }) {
  return (dispatch) => {
    dispatch({
      type: ideaStepFluxUpdateConstants.EDIT_IDEA_STEP_FLUX_UPDATE_IS_GENERIC,
      payload: { name, checked }
    });
  }
}

function clearAllEditIdeaStepFlux() {
  return (dispatch) => {
    dispatch({ type: ideaStepFluxUpdateConstants.EDIT_IDEA_STEP_FLUX_UPDATE_CLEAR_ALL });
  }
}